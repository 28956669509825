import { ColorMap } from 'src/themes/Attributes';
import { SwitchProps } from '../../../types/InputComponentProps';
import { Checkbox, FormControl } from '@chakra-ui/react';

export function BooleanInput(props: SwitchProps) {
  const { id, label, isInvalid, isDisabled, size: _size, className, ...rest } = props;
  return (
    <FormControl id={id} label={label} isInvalid={isInvalid} isDisabled={isDisabled} className={className}>
      {/**@ts-expect-error*/}
      <Checkbox id={id} {...rest} borderColor={ColorMap.primaryDark}>
        {label}
      </Checkbox>
    </FormControl>
  );
}

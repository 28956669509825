import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { Project, ProjectRecord } from '@karya/core';
import { useAppDispatch } from 'src/app/Store';
import { backendApi } from 'src/features/apiSlice';
import { updateCurrentProject } from 'src/features/projectsSlice';
import { createProjectForm } from 'src/forms/projects/Create';
import { ChakraFormRenderer } from 'src/helpers/parameter-renderer/backends';
import { useGenericApiForm } from 'src/hooks/GenericForm';
import { useFlagEffect } from 'src/hooks/Misc';
import { useSelectProject } from 'src/hooks/SelectProject';
import { ButtonVariant } from 'src/themes/ButtonVariants';

export function CreateProjectModal(props: {
  disclosure: ReturnType<typeof useDisclosure>;
  projectModal: ReturnType<typeof useDisclosure>;
}) {
  const { disclosure, projectModal } = props;
  const { ctx, isLoading, isSuccess, handleSubmit, data } = useGenericApiForm<Project, ProjectRecord>({
    parameters: createProjectForm,
    successToast: 'Project Created Successfully',
    errorToast: 'Failed to create project',
    apiMutation: backendApi.useCreateProjectMutation,
    isKeyPath: true,
  });

  const dispatch = useAppDispatch();
  const selectProject = useSelectProject();

  useFlagEffect(isSuccess, () => {
    if (isSuccess) {
      dispatch(updateCurrentProject(data!));
      disclosure.onClose();
      projectModal.onClose();
      selectProject(data!);
    }
  });

  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} size={{ sm: 'lg', md: '2xl', lg: '4xl', xl: '6xl' }}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">New Project</ModalHeader>
        <ModalCloseButton top="1rem" />
        <ModalBody>
          <ChakraFormRenderer ctx={ctx} />
        </ModalBody>
        <ModalFooter>
          <Button variant={ButtonVariant.secondary_outlined} onClick={disclosure.onClose} mr="10px">
            Cancel
          </Button>
          <Button variant={ButtonVariant.secondary} onClick={handleSubmit} isLoading={isLoading}>
            Create Project
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import { Route, Routes } from 'react-router-dom';
import { Report } from 'src/components/Reports/Report';
import { LoginRegister } from 'src/screens/LoginRegister';
import { Logout } from 'src/screens/Logout';
import { Main } from 'src/screens/Main';
import { Reports } from 'src/screens/reports/Index';

const Router = (
  <Routes>
    <Route path="/" Component={LoginRegister} />;
    <Route path="/login" Component={LoginRegister} />;
    <Route path="/register" Component={LoginRegister} />;
    <Route path="/logout" Component={Logout} />
    <Route path="/*" Component={Main} />
    <Route path="/mobile/reports" element={<Reports />} />
    <Route path="/mobile/reports/:kind/:id" element={<Report src="mobile" />} />
    <Route path="/web/reports/:kind/:id" element={<Report src="web" />} />
  </Routes>
);

export default Router;

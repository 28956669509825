import { GenericInputParam, GenericOutputParam, NgGenericTask } from '@karya/core';

export const metadataFields = ['key', 'name', 'description', 'ctype', 'dtype', 'function_id', 'ltype'] as const;

export type MetadataField = (typeof metadataFields)[number];
export type ComponentParamKey<T extends NgGenericTask[string]> = Exclude<keyof T, MetadataField>;

export type ParamValue<T extends GenericInputParam | GenericOutputParam> = T extends GenericInputParam
  ? Extract<T, { src: 'CONSTANT' }>['value']
  : T['type'] extends 'STRING' | 'ENUM' | 'FILE'
  ? string | null
  : T['type'] extends 'INT' | 'FLOAT'
  ? number | null
  : T['type'] extends 'BOOLEAN'
  ? boolean | null
  : T['type'] extends 'STRING_ARRAY'
  ? Array<string> | null
  : T['type'] extends 'ARRAY'
  ? Array<any> | null
  : T['type'] extends 'INT_ARRAY'
  ? Array<number> | null
  : T['type'] extends 'REF'
  ? NgGenericTask[string] | null
  : T['type'] extends 'ANY'
  ? any
  : never;

export type ParsedParams<T extends NgGenericTask[string]> = {
  [key in ComponentParamKey<T>]: undefined extends T[key]
    ? ParamValue<Extract<T[key], GenericInputParam | GenericOutputParam>> | null
    : ParamValue<Extract<T[key], GenericInputParam | GenericOutputParam>>;
} & { next: NgGenericTask[string] };

export type ComponentNode<T extends NgGenericTask[string] = NgGenericTask[string]> = {
  component: T;
  params: ParsedParams<T>;
};

export type ComponentPath = Array<ComponentNode>;

import { Box, Button, HStack } from '@chakra-ui/react';
import React from 'react';
import { ChakraFormRenderer } from 'src/helpers/parameter-renderer/backends';
import { useTaskBuilder } from 'src/hooks/TaskBuilder';
import { ButtonVariant } from 'src/themes/ButtonVariants';

export type ComponentBuilderProps = {
  ctx: ReturnType<typeof useTaskBuilder>;
};

export const ComponentBuilder = React.memo((props: ComponentBuilderProps) => {
  const { ctx, handleSubmit, resetForm } = props.ctx.componentForm;

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <ChakraFormRenderer ctx={ctx} />
      <HStack>
        <Button variant={ButtonVariant.secondary_outlined} onClick={resetForm}>
          Reset
        </Button>
        <Button variant={ButtonVariant.secondary} type="submit">
          Add Component
        </Button>
      </HStack>
    </Box>
  );
});

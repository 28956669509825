import { Tag } from '@chakra-ui/react';
import { ColorMap } from 'src/themes/Attributes';

export function TagsList(props: { tags: string[] }) {
  return (
    <>
      {props.tags.map((tag, index) => (
        <Tag key={index} backgroundColor={ColorMap.stockGray} textColor={ColorMap.white}>
          {tag}
        </Tag>
      ))}
    </>
  );
}

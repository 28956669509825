import { Card } from '@chakra-ui/react';
import { NgGenericTask } from '@karya/core';
import { ReactNode } from 'react';
import { ColorMap } from 'src/themes/Attributes';

type BubbleProps = {
  children: ReactNode;
  type: NgGenericTask[string]['ctype'];
};
export function Bubble(props: BubbleProps) {
  const { children, type } = props;
  let location;
  switch (type) {
    case 'USER':
      location = 'end';
      break;
    case 'PLATFORM':
      location = 'start';
      break;
    case 'BACKGROUND_LOCAL':
      location = 'center';
      break;
    default:
      return null;
  }
  return (
    <Card alignSelf={location} p="10px" bg={ColorMap.white} borderRadius="5px" maxWidth="80%">
      {children}
    </Card>
  );
}

import { Box, Checkbox, Heading, HStack, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { BarChart } from './BarChart';

type Datapoint = {
  id: string;
  label: string;
  value: number;
};

type FilteredGroupedBarChartProps = {
  title?: string;
  yLabel: string;
  data: {
    id: string;
    label: string;
    value: Datapoint[];
  }[];
  showFilters?: boolean;
};

export const FilteredGroupedBarChart = ({ title, yLabel, data, showFilters = true }: FilteredGroupedBarChartProps) => {
  const subItems = data[0].value.map((d) => ({
    id: d.id,
    label: d.label,
  }));

  const DEFAULT_SELECTED = Math.min(Math.floor(subItems.length), 6);
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    [...Array(subItems.length)].map((_, i) => i < DEFAULT_SELECTED)
  );
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  const selectionData = subItems.filter((_, index) => checkedItems[index]);

  return (
    <Box
      height={650}
      boxShadow="md"
      rounded="lg"
      p={4}
      border="solid 1px"
      borderColor="gray.100"
      width={showFilters ? 'min' : '672px'}
    >
      {title && (
        <Heading size="md" textAlign="center">
          {title}
        </Heading>
      )}
      <HStack height={600} py={8} px={4} width={showFilters ? 'min' : '672px'}>
        {showFilters && (
          <VStack flex={1} align="start" height={600} width={150} py={4} overflow={'scroll'}>
            <Checkbox
              size="sm"
              isChecked={allChecked}
              isIndeterminate={isIndeterminate}
              onChange={(e) => setCheckedItems([...Array(data.length)].map(() => e.target.checked))}
            >
              Select All
            </Checkbox>
            <VStack pl={6} mt={1} align="start">
              {subItems.map((subItem, index) => (
                <Checkbox
                  key={subItem.id}
                  size="sm"
                  isChecked={checkedItems[index]}
                  onChange={(e) => {
                    const newCheckedItems = [...checkedItems];
                    newCheckedItems[index] = e.target.checked;
                    setCheckedItems([...newCheckedItems]);
                  }}
                >
                  {subItem.label}
                </Checkbox>
              ))}
            </VStack>
          </VStack>
        )}

        <BarChart
          data={{
            labels: selectionData.map((d) => d.label),
            datasets: data.map((datum) => {
              const selectedValues = datum.value.filter((_, index) => checkedItems[index]);
              return {
                label: datum.label + ' ' + yLabel,
                data: selectedValues.map((d) => d.value),
              };
            }),
          }}
        />
      </HStack>
    </Box>
  );
};

import { GTParamsArray } from '../../Index';
import {
  ArrayFilterLocalComponentParameters,
  CheckClippingLocalComponentParameters,
  ForLoopComponentParameters,
  NgGenericTask,
  NullCheckLocalComponentParameters,
  PlatformAudioComponentParameters,
  PlatformImageComponentParameters,
  PlatformTextComponentParameters,
  PlatformVideoComponentParameters,
  SegmentAudioLocalComponentParameters,
  UserAudioComponentParameters,
  UserAudioMarkerParameters,
  UserAudioSegmentationParameters,
  UserBooleanComponentParameters,
  UserImageComponentParameters,
  UserMCQComponentParameters,
  UserTextComponentParameters,
  UserVideoComponentParameters,
  WhileLoopComponentParameters,
} from '../Index';

function assertUnreachable(_x: never): never {
  throw new Error("Didn't expect to get here");
}

export function getComponentParameters(component: NgGenericTask[string]): GTParamsArray {
  if (component.ctype === 'USER') {
    switch (component.dtype) {
      case 'TEXT':
        return UserTextComponentParameters;

      case 'AUDIO':
        return UserAudioComponentParameters;

      case 'MCQ':
        return UserMCQComponentParameters;

      case 'IMAGE':
        return UserImageComponentParameters;

      case 'VIDEO':
        return UserVideoComponentParameters;

      case 'BOOLEAN':
        return UserBooleanComponentParameters;

      case 'AUDIO_SEGMENTATION':
        return UserAudioSegmentationParameters;

      case 'AUDIO_MARKER':
        return UserAudioMarkerParameters;

      default:
        assertUnreachable(component);
    }
  } else if (component.ctype === 'PLATFORM') {
    switch (component.dtype) {
      case 'AUDIO':
        return PlatformAudioComponentParameters;

      case 'TEXT':
        return PlatformTextComponentParameters;

      case 'IMAGE':
        return PlatformImageComponentParameters;

      case 'VIDEO':
        return PlatformVideoComponentParameters;

      default:
        assertUnreachable(component);
    }
  } else if (component.ctype === 'BACKGROUND_LOCAL') {
    switch (component.function_id) {
      case 'SEGMENT_AUDIO':
        return SegmentAudioLocalComponentParameters;

      case 'CHECK_AUDIO_CLIPPING':
        return CheckClippingLocalComponentParameters;

      case 'ARRAY_FILTER':
        return ArrayFilterLocalComponentParameters;

      case 'NULL_CHECK':
        return NullCheckLocalComponentParameters;

      default:
        assertUnreachable(component);
    }
  } else if (component.ctype === 'LOOP') {
    switch (component.ltype) {
      case 'WHILE':
        return WhileLoopComponentParameters;
      case 'FOR':
        return ForLoopComponentParameters;
    }
  } else if (component.ctype === 'START' || component.ctype === 'END') {
    return [];
  }
  return assertUnreachable(component);
}

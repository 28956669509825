import { StartComponent } from '@karya/core';
import { InputSourcePreview, PreviewBodyHolder } from '../Index';

export function StartComponentPreviewBody({ component }: { component: StartComponent }) {
  return (
    <PreviewBodyHolder>
      <InputSourcePreview label="Next" param={component.next} />
    </PreviewBodyHolder>
  );
}

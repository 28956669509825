import { Dataset, DatasetProps } from '@karya/core';
import { ParameterForm } from '@karya/parameter-specs';
import { backendApi } from 'src/features/apiSlice';
import { useForm } from 'src/helpers/parameter-renderer/hooks';
import { useFlagEffect } from 'src/hooks/Misc';
import { useApiToastEffect } from 'src/hooks/Toasts';

export const datasetFormParameters: ParameterForm<DatasetProps> = [
  {
    label: 'Dataset Info',
    required: true,
    parameters: [
      {
        id: 'name',
        label: 'Name',
        type: 'string',
        required: true,
      },
      {
        id: 'tags',
        label: 'Dataset Tags',
        description: 'Provide a set of tags that you want to associate with this dataset',
        type: 'list',
        required: true,
      },
    ],
  },
];

export function useDatasetCreateForm() {
  // Dataset create query
  const [createTrigger, { isLoading, isSuccess, error, data }] = backendApi.useCreateDatasetMutation();

  // Form
  const { ctx, validate, resetForm } = useForm<DatasetProps>({ parameters: datasetFormParameters });

  // Handle dataset creation
  const handleDatasetSubmit = () => {
    const { value, error } = validate();
    if (error || !value) return;

    const dataset: Dataset = {
      name: value.name,
      props: {
        ...value,
        dpCount: 0,
      },
      dtype: 'NAN',
      source: {},
      summary: {
        dp_count: 0,
      },
    };

    createTrigger(dataset);
  };

  // On success, reset form
  useFlagEffect(isSuccess, resetForm);

  // Use toas
  useApiToastEffect({
    isLoading,
    isSuccess,
    isError: error != undefined,
    successToast: 'Successfully created dataset',
    errorToast: 'Failed to create dataset',
  });

  return {
    ctx,
    handleDatasetSubmit,
    isDatasetCreating: isLoading,
    isDatasetCreated: isSuccess,
    dataset: data,
  };
}

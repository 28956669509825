import {
  getComponentParameters,
  GTParamsArray,
  GTParamsDefination,
  InputSource,
  inputSources,
  NgGenericTask,
  NgUnitComponent,
} from '@karya/core';
import { ParameterDefinition, ParameterSection } from '@karya/parameter-specs';

import {
  endComponentMetadataParameters,
  localFunctionType,
  loopComponentLtype,
  metadataSection,
  nextParam,
  platformDType,
  startComponentMetadataParameters,
  userDType,
} from './Basic';

type ComponentBuilderState = {
  ctype?: NgGenericTask[string]['ctype'];
  dtype?: Extract<NgGenericTask[string], { ctype: 'PLATFORM' | 'USER' }>['dtype'];
  function_id?: Extract<NgGenericTask[string], { ctype: 'BACKGROUND_LOCAL' }>['function_id'];
  ltype?: Extract<NgGenericTask[string], { ctype: 'LOOP' }>['ltype'];
  [key: string]: any;
};
export function generateComponentParamSections(state: ComponentBuilderState, spec: NgGenericTask) {
  const baseSection = structuredClone(metadataSection) as typeof metadataSection;
  const { ctype, dtype, ltype, function_id, name: _name, description: _description } = state;
  if (!ctype) {
    return [baseSection];
  }
  switch (ctype) {
    case 'USER':
      baseSection.parameters.push(userDType);
      break;
    case 'PLATFORM':
      baseSection.parameters.push(platformDType);
      break;

    case 'LOOP': {
      baseSection.parameters.push(loopComponentLtype);
      break;
    }
    case 'BACKGROUND_LOCAL':
      baseSection.parameters.push(localFunctionType);
      break;
    case 'START': {
      baseSection.parameters = startComponentMetadataParameters;
      break;
    }
    case 'END': {
      baseSection.parameters = endComponentMetadataParameters;
      break;
    }
  }

  let genericTaskParameters: GTParamsArray = getGTParamsArray({
    ctype,
    dtype,
    function_id,
    ltype,
  });
  const componentParamSections = genericTaskParameters.map((param) => {
    return converGtParamsToSections(param, state, spec);
  });

  const formDef = [baseSection, ...componentParamSections];
  if (genericTaskParameters.length !== 0 || ctype === 'START') {
    formDef.push(converGtParamsToSections(nextParam, state, spec));
  }

  return formDef;
}

export function getGTParamsArray({ ctype, dtype, function_id, ltype }: ComponentBuilderState) {
  try {
    // @ts-expect-error
    return getComponentParameters({ ctype, dtype, function_id, ltype });
  } catch (err) {
    return [];
  }
}

// TODO: Refactor, cleanup and document this fucntion
function converGtParamsToSections(
  paramDef: GTParamsDefination<any, any>,
  state: ComponentBuilderState,
  spec: NgGenericTask
) {
  const getId = (type: 'src' | 'key' | 'value' | 'options' | 'itype' | 'componentKey') => `${paramDef.id}.${type}`;
  const getDescription = (type: 'Source' | 'Key' | 'Value') => `${paramDef.label} ${type}`;

  const section: ParameterSection<any> = {
    label: paramDef.label,
    description: paramDef.description,
    parameters: [],
    required: true,
  };

  const keyDef: ParameterDefinition<any> = {
    id: getId('key'),
    label: 'Key',
    description: getDescription('Key'),
    type: 'string',
    required: true,
  };
  const srcDef: ParameterDefinition<any> = {
    id: getId('src'),
    label: 'Source',
    description: `${paramDef.label} Source`,
    type: 'enum',
    list: inputSources,
    required: paramDef.required,
  };

  const typeDef: ParameterDefinition<any> = {
    id: `${paramDef.id}.type`,
    label: 'Parameter Type',
    type: 'fixed',
    value: paramDef.paramType,
    required: false,
  };

  const paramInUse = state[getId('src')] || state[getId('key')];
  if (paramDef.paramType !== 'SPEC' && paramInUse) {
    section.parameters.push(typeDef);
  }

  if (paramDef.type === 'output') {
    section.parameters.push(keyDef);
    return section;
  }

  const isBoolean = paramDef.type === 'boolean';
  // @ts-expect-error
  const valueDef: ParameterDefinition<any> = {
    id: getId('value'),
    label: isBoolean ? paramDef.label : 'Value',
    description: getDescription('Value'),
    type: paramDef.type,
    // @ts-expect-error
    value: paramDef.value,
    // @ts-expect-error
    list: paramDef.list,
    required: paramDef.required,
  };

  if (paramDef.paramType === 'SPEC') {
    section.parameters.push(valueDef);
    return section;
  }

  // Push paramter definition for src if the param is an input param
  section.parameters.push(srcDef);

  const currSource = state[getId('src')] as InputSource;

  if (paramDef.type === 'enum' && currSource) {
    const enumOptions: ParameterDefinition<any> = {
      id: getId('options'),
      label: 'Enum Options',
      type: 'fixed',
      value: paramDef.list,
      required: false,
    };
    section.parameters.push(enumOptions);
  }

  if (!currSource) {
    return section;
  }

  if (currSource === 'CONSTANT') {
    // HACK: Set type to 'file' for constant file param to allow for upload
    if (paramDef.paramType === 'FILE') {
      section.parameters.push({ ...valueDef, type: 'file' });
    } else {
      section.parameters.push(valueDef);
    }
  } else {
    section.parameters.push(keyDef);
  }

  if (paramDef.paramType !== 'REF' || currSource === 'CONSTANT') {
    return section;
  }

  // If the current param is a REF component
  const itypeDef: ParameterDefinition<any> = {
    id: getId('itype'),
    label: 'Branching component',
    description: 'The type of component whose output which is to be used to evaluate the next component',
    type: 'enum',
    list: {
      BOOLEAN: 'Boolean',
    },
    required: paramDef.required,
  };
  if (currSource === 'MTA_OUTPUT') {
    itypeDef.list['STRING_ARRAY'] = 'Multiple Choice';
  }
  section.parameters.push(itypeDef);

  if (!state[getId('itype')]) {
    return section;
  }

  const getBranchDef = (branch: string | boolean) => {
    const branchAsStr = branch.toString();
    const branchName = branchAsStr.charAt(0).toUpperCase() + branchAsStr.slice(1);
    const branchDef: ParameterDefinition<any> = {
      id: `${getId('options')}.${branch}`,
      label: `${branchName} Reference`,
      description: `Key referencing the next component for branch - '${branchAsStr}'`,
      type: 'string',
      required: true,
    };
    return branchDef;
  };

  // If the current param uses a mcq component as base
  const mcqComponentEntries = Object.values<Extract<NgUnitComponent, { dtype: 'MCQ' }>>(spec as any)
    .filter((component) => component.ctype === 'USER' && component.dtype === 'MCQ')
    .filter((component) => component.options.src === 'CONSTANT')
    .map((component) => [component.key, component.name ?? component.key]);

  const componentSelectDef: ParameterDefinition<any> = {
    id: `${getId('componentKey')}`,
    label: 'Base MCQ Component',
    description: 'Multiple Choice component to use for branching',
    type: 'enum',
    list: Object.fromEntries(mcqComponentEntries),
    required: true,
  };
  const selectedComponentKey = state[getId('componentKey')];

  switch (state[getId('itype')]) {
    case 'BOOLEAN': {
      const variableKeyDef: ParameterDefinition<any> = {
        id: getId('key'),
        label: 'Test Variable Key',
        description: `Refers to the location within '${inputSources[currSource]}' that you want to use for branching`,
        type: 'string',
        required: true,
      };
      section.parameters.push(variableKeyDef, getBranchDef(true), getBranchDef(false));
      break;
    }
    case 'STRING_ARRAY':
      section.parameters.push(componentSelectDef);
      if (selectedComponentKey) {
        const branchBase = spec[selectedComponentKey] as Extract<NgUnitComponent, { dtype: 'MCQ' }>;

        const variableKeyDef: ParameterDefinition<any> = {
          id: getId('key'),
          label: 'Variable Key',
          description: `Refers to the location within ${currSource} that you want to use for branching`,
          type: 'fixed',
          value: branchBase.key,
          required: false,
        };

        // @ts-expect-error options is always has constant souce (due to filter)
        const branchDefs = branchBase.options.value.map((branch) => getBranchDef(branch));
        section.parameters.push(variableKeyDef, ...branchDefs);
      }
      break;
  }

  return section;
}

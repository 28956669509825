import {
  BackgroundLocalComponent,
  GTParamsDefination,
  NgLoopComponent,
  NgUnitComponent,
  PlatformComponent,
  StartComponent,
  UserComponent,
} from '@karya/core';
import { ParameterArray, ParameterDefinition, ParameterSection } from '@karya/parameter-specs';

// TODO: Move to @karya/core
const userDTypeList: Record<UserComponent['dtype'], string> = {
  TEXT: 'Text',
  AUDIO: 'Audio',
  MCQ: 'Multiple Choice',
  IMAGE: 'Image',
  VIDEO: 'Video',
  BOOLEAN: 'Boolean',
  AUDIO_SEGMENTATION: 'Audio Segmenation',
  AUDIO_MARKER: 'Audio Timestamp Marker',
};

export const userDType: ParameterDefinition<any> = {
  id: 'dtype',
  label: 'Component Data Type',
  description: 'Data type of the component',
  type: 'enum',
  list: userDTypeList,
  required: true,
};

// TODO: Move to @karya/core
const platformDTypeList: Record<PlatformComponent['dtype'], string> = {
  TEXT: 'Text',
  AUDIO: 'Audio',
  IMAGE: 'Image',
  VIDEO: 'Video',
};

export const platformDType: ParameterDefinition<any> = {
  id: 'dtype',
  label: 'Component Data Type',
  description: 'Data type of the component',
  type: 'enum',
  list: platformDTypeList,
  required: true,
};

// TODO: Move to @karya/core
const backgroundLocalFunctionList: Record<BackgroundLocalComponent['function_id'], string> = {
  CHECK_AUDIO_CLIPPING: 'Check Clipping',
  SEGMENT_AUDIO: 'Automatic audio segmentations',
  ARRAY_FILTER: 'Array Filtering',
  NULL_CHECK: 'Null Check',
};

export const localFunctionType: ParameterDefinition<any> = {
  id: 'function_id',
  label: 'Function Type',
  description: 'Type of function to execute within the background component',
  type: 'enum',
  list: backgroundLocalFunctionList,
  required: true,
};

export const loopComponentLtype: ParameterDefinition<any> = {
  id: 'ltype',
  label: 'Function Type',
  description: 'Type of function to execute within the background component',
  type: 'enum',
  list: {
    FOR: 'For Loop',
    WHILE: 'While Loop',
  },
  required: true,
};

// TODO: Change to NgGenericTask[string]
export const metadataSection: ParameterSection<NgUnitComponent | NgLoopComponent> = {
  label: 'Component Metadata',
  description: 'Basic information about the component that you are creating',
  required: true,
  parameters: [
    {
      id: 'key',
      label: 'Key',
      description: 'Component Key',
      type: 'string',
      required: true,
    },
    {
      id: 'name',
      label: 'Name',
      description: 'Name of the component',
      type: 'string',
      required: true,
    },
    {
      id: 'description',
      label: 'Component Description',
      description: 'Description of the component',
      type: 'string',
      required: false,
    },
    {
      id: 'ctype',
      label: 'Component Type',
      description: 'Type of the component',
      type: 'enum',
      list: {
        PLATFORM: 'Platform',
        USER: 'User',
        BACKGROUND_LOCAL: 'Background (Local)',
        LOOP: 'Loop',
      },
      required: true,
    },
  ],
};

export const startComponentMetadataParameters: ParameterArray<StartComponent> = [
  {
    id: 'key',
    label: 'Key',
    description: 'Component Key',
    type: 'fixed',
    value: 'start',
    required: false,
  },
  {
    id: 'name',
    label: 'Name',
    description: 'Name of the component',
    type: 'fixed',
    value: 'Start Component',
    required: false,
  },
  {
    id: 'description',
    label: 'Component Description',
    description: 'Description of the component',
    type: 'fixed',
    value: 'First Component of the task specification',
    required: false,
  },
  {
    id: 'ctype',
    label: 'Component Type',
    description: 'Type of the component',
    type: 'enum',
    list: {
      START: 'Specification Start',
      PLATFORM: 'Platform',
      USER: 'User',
      BACKGROUND_LOCAL: 'Background (Local)',
      LOOP: 'Loop',
    },
    required: true,
  },
];
export const endComponentMetadataParameters: ParameterArray<StartComponent> = [
  {
    id: 'key',
    label: 'Key',
    description: 'Component Key',
    type: 'fixed',
    value: 'end',
    required: false,
  },
  {
    id: 'name',
    label: 'Name',
    description: 'Name of the component',
    type: 'fixed',
    value: 'End Component',
    required: false,
  },
  {
    id: 'description',
    label: 'Component Description',
    description: 'Description of the component',
    type: 'fixed',
    value: 'Last Component of the task specification',
    required: false,
  },
  {
    id: 'ctype',
    label: 'Component Type',
    description: 'Type of the component',
    type: 'enum',
    list: {
      END: 'Specification End',
      PLATFORM: 'Platform',
      USER: 'User',
      BACKGROUND_LOCAL: 'Background (Local)',
      LOOP: 'Loop',
    },
    required: true,
  },
];

export const nextParam: GTParamsDefination<'next', any> = {
  id: 'next',
  label: 'Next Component',
  description: 'Reference to the next component',
  paramType: 'REF',
  type: 'string',
  required: true,
};

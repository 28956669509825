import { ComponentRendererProps } from '../ComponentRenderer';
import { Bubble } from '../Bubble';
import { PlatformTextRenderer } from './Text';
import { AudioRenderer } from '../common/Audio';
import { ImageRenderer } from '../common/Image';
import { VideoRenderer } from '../common/Video';

export function PlatformComponentRenderer(props: ComponentRendererProps) {
  if (props.node.component.ctype != 'PLATFORM' || props.isDocked) {
    return null;
  }
  let component: JSX.Element;
  switch (props.node.component.dtype) {
    case 'TEXT':
      component = <PlatformTextRenderer params={props.node.params} />;
      break;
    case 'VIDEO':
      component = <VideoRenderer />;
      break;
    case 'IMAGE':
      component = <ImageRenderer />;
      break;
    case 'AUDIO':
      component = <AudioRenderer />;
      break;
  }
  return <Bubble type="PLATFORM">{component}</Bubble>;
}

import { ParameterForm } from '@karya/parameter-specs';

export const createBoxFormParameters: ParameterForm = [
  {
    label: 'Create Box',
    required: true,
    parameters: [
      {
        id: 'name',
        label: 'Name',
        description: 'Name of the box',
        type: 'string',
        required: true,
      },
    ],
  },
];

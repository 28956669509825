import {
  GenericInputParam,
  GenericOutputParam,
  InputSource,
  MicrotaskInput,
  MicrotaskOutput,
  NgGenericTask,
  ReferenceInputParam,
  TaskParams,
} from '@karya/core';
import { MetadataField, metadataFields, ParsedParams } from './Types';

export type ParseComponentSources = {
  spec: NgGenericTask;
  params: Partial<TaskParams>;
  mtInput: MicrotaskInput<Record<string, any>, Record<string, string>>;
  mtaOutput: MicrotaskOutput<Record<string, any>, Record<string, string>>;
};
export function parseComponent<T extends NgGenericTask[string]>(
  component: T,
  sources: ParseComponentSources,
  fileUrlMap?: Record<string, string>
): ParsedParams<T> {
  // get Object entries for all component params
  const parsedParamEntries = Object.entries(component)
    .filter(([name]) => !metadataFields.includes(name as MetadataField))
    .map(([name, param]) => {
      return [name, parseParam(sources.spec, param, sources.params, sources.mtInput, sources.mtaOutput, fileUrlMap)];
    });
  return Object.fromEntries(parsedParamEntries);
}

function parseParam(
  spec: NgGenericTask,
  param: GenericInputParam | GenericOutputParam,
  taskParams: Partial<TaskParams>,
  mtInput: MicrotaskInput<Record<string, any>, Record<string, string>>,
  mtaOutput: MicrotaskOutput<Record<string, any>, Record<string, string>>,
  fileUrlMap?: Record<string, string>
) {
  if (param.type === 'REF') {
    return getReferencedComponent(spec, param, taskParams, mtInput, mtaOutput);
  }
  return getValueFromSource(param, taskParams, mtInput, mtaOutput, fileUrlMap);
}

function getValueFromSource(
  param: GenericInputParam | GenericOutputParam,
  taskParams: Partial<TaskParams>,
  mtInput: MicrotaskInput<Record<string, any>, Record<string, string>>,
  mtaOutput: MicrotaskOutput<Record<string, any>, Record<string, string>>,
  fileUrlMap?: Record<string, string>
) {
  let source: Exclude<InputSource, 'CONSTANT'> = 'MTA_OUTPUT';

  if ('src' in param && param.src === 'CONSTANT') {
    return param.value;
  }
  if ('src' in param) {
    source = param.src;
  }

  const paramLoc = param.type === 'FILE' ? 'files' : 'data';

  const microtaskInput = mtInput[paramLoc] ?? {};
  const assignmentOutput = mtaOutput[paramLoc] ?? {};

  const sources: Record<Exclude<InputSource, 'CONSTANT'>, Record<string, any>> = {
    MT_INPUT: microtaskInput,
    MTA_OUTPUT: assignmentOutput,
    TASK_PARAM: taskParams,
    ITER: {},
    APP_PARAM: {},
    WORKER_PROFILE: {
      language: 'en',
      gender: 'Female',
      yob: 1990,
      full_name: 'Jane Doe',
      phone_number: '1234567890',
    },
  };
  const value = sources[source][param.key] ?? null;
  if (paramLoc === 'data') {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  }

  // Return file url param is a file
  if (param.type === 'FILE' && fileUrlMap && typeof value == 'string' && value in fileUrlMap) {
    return fileUrlMap[value] ?? null;
  }
  return null;
}

function getReferencedComponent(
  spec: NgGenericTask,
  param: ReferenceInputParam,
  params: Partial<TaskParams>,
  mtInput: MicrotaskInput<Record<string, any>, Record<string, string>>,
  mtaOutput: MicrotaskOutput<Record<string, any>, Record<string, string>>
) {
  let refKey: string | null = null;

  if (param.src === 'CONSTANT') {
    // Get reference from "CONSTANT" key specified in param.value
    refKey = param.value;
  } else {
    // Get reference by evaluating the condition variable obtained from the correct source
    const condition = getValueFromSource(param, params, mtInput, mtaOutput);

    if (condition != null) {
      refKey = param.options[condition];
    } else if ('default' in param.options) {
      refKey = param.options.default;
    }
  }

  if (refKey !== null) {
    return spec[refKey];
  }
  return null;
}

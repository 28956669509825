import { NgGenericTask } from '@karya/core';
import { Text, VStack } from '@chakra-ui/react';
import React from 'react';
import _ from 'lodash';
import { ComponentPreview } from './previewBody/Index';

export type ReadonlyTaskPreviewProps = {
  spec: NgGenericTask;
  deleteComponent: (name: string) => void;
};
export const ReadonlyTaskPreview = React.memo((props: ReadonlyTaskPreviewProps) => {
  const { spec, deleteComponent } = props;
  const componentList = Object.values(spec);

  const componentPreviews = componentList.map((component) => (
    <ComponentPreview component={component} key={component.key} deleteComponent={deleteComponent} />
  ));

  return (
    <VStack flex={1} alignItems="start">
      <Text fontSize="lg" fontWeight="bold">
        {componentList.length > 0 && 'Task Preview'}
      </Text>
      {componentPreviews}
    </VStack>
  );
});

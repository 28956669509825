import { v4 as uuidV4 } from 'uuid';
import { useCallback, useState } from 'react';

export function useFilterBuilder<T extends Record<string, any>>() {
  const [filters, setFilters] = useState<Record<string, [keyof T | '', string, boolean]>>({});
  const buildFilterStr = useCallback(() => {
    return Object.values(filters)
      .map((expr) => {
        if (expr[2] === true) {
          return [expr[0], `'${expr[1]}'`];
        }
        return [expr[0], expr[1]];
      })
      .map((expr) => expr.join(' EQ '))
      .join(' AND ');
  }, [filters]);
  const setExpr = useCallback(
    (exprId: string, key: keyof T, val: any, isString: boolean) => {
      setFilters((filters) => {
        return { ...filters, [exprId]: [key, val, isString] };
      });
    },
    [setFilters]
  );
  const addFilter = useCallback(() => {
    setFilters((filters) => {
      const id = uuidV4();
      const updatedFilters: typeof filters = {
        ...filters,
        [id]: ['' as keyof T, '', true],
      };
      return updatedFilters;
    });
  }, [setFilters]);
  const deleteFilter = useCallback(
    (id: string) => {
      setFilters((filters) => {
        const { [id]: _delete, ...rest } = filters;
        return rest;
      });
    },
    [setFilters]
  );
  const resetFilters = useCallback(() => setFilters({}), [setFilters]);
  return { ctx: { setExpr, addFilter, deleteFilter, filters }, buildFilterStr, resetFilters };
}

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  Image,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { HiMiniPhoto, HiOutlineArrowDownTray, HiXMark } from 'react-icons/hi2';

type Props = {
  url: string;
};

const getFileType = (url: string) => {
  const filename = url.split('?')[0];
  const ext = filename.split('.').slice(-1)[0];
  switch (ext) {
    case 'jpg':
    case 'png':
    case 'jpeg':
      return 'image';
    case 'mp3':
    case 'wav':
    case 'ogg':
    case '3gp':
      return 'audio';
    case 'mp4':
    case 'mov':
    case 'avi':
      return 'video';
    default:
      return 'unsupported';
  }
};

export const FilePreview = ({ url }: Props) => {
  const fileType = getFileType(url);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);

  switch (fileType) {
    case 'audio':
      return <ReactAudioPlayer src={url} controls />;
    case 'image':
      return (
        <>
          <Button
            ref={btnRef}
            onClick={onOpen}
            size="sm"
            leftIcon={<Icon as={isOpen ? HiXMark : HiMiniPhoto} />}
            background={isOpen ? 'gray.300' : 'gray.100'}
          >
            {isOpen ? 'Close' : 'Preview'}
          </Button>
          <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef} size="md">
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader>File Preview</DrawerHeader>
              <DrawerCloseButton />
              <DrawerBody>
                <VStack spacing={4} align="start">
                  <Image src={url} alt={url.split('?')[0]} />
                  <Button as="a" href={url} download leftIcon={<Icon as={HiOutlineArrowDownTray} />}>
                    Download
                  </Button>
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      );
    default:
      return null;
  }
};

import { useFilterBuilder } from 'src/hooks/FilterBuilder';
import React, { ChangeEventHandler } from 'react';
import { Badge, Box, Button, HStack, IconButton, VStack } from '@chakra-ui/react';
import { FormInput } from 'src/helpers/parameter-renderer/backends/chakra/components';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { BooleanInput } from 'src/helpers/parameter-renderer/backends/chakra/components/BooleanInput';

type FilterBuilderProps<_T extends Record<string, any>> = {
  ctx: ReturnType<typeof useFilterBuilder<_T>>['ctx'];
};
export const FilterBuilder = React.memo(function <T extends Record<string, any>>(props: FilterBuilderProps<T>) {
  const { ctx } = props;
  const { filters } = ctx;
  const keyChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const id = e.target.name;
    ctx.setExpr(id, e.target.value, filters[id][1], filters[id][2]);
  };
  const valChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const id = e.target.name;
    ctx.setExpr(id, filters[id][0], e.target.value, filters[id][2]);
  };
  const isStringChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const id = e.target.name;
    ctx.setExpr(id, filters[id][0], filters[id][1], e.target.checked);
  };

  return (
    <VStack width="100%" alignItems="flex-start">
      {Object.entries(ctx.filters).map(([uuid, [key, val, isString]]) => (
        <HStack key={uuid} alignItems="center">
          <FormInput label="Key" name={uuid} value={key as string} onChange={keyChangeHandler} isRequired />
          <Badge height="100%" marginTop="3%">
            EQUALS
          </Badge>
          <FormInput label="Value" name={uuid} value={val as string} onChange={valChangeHandler} isRequired />
          <Box
            as={BooleanInput}
            label="Is String"
            name={uuid}
            isChecked={isString}
            isRequired={val.includes(' ')}
            onChange={isStringChangeHandler}
            marginTop="3%"
          />
          <IconButton
            aria-label="Delete"
            colorScheme="red"
            icon={<DeleteIcon />}
            onClick={() => ctx.deleteFilter(uuid)}
            marginTop="3%"
          />
        </HStack>
      ))}
      <Button variant="link" leftIcon={<AddIcon />} onClick={ctx.addFilter}>
        Add Rule
      </Button>
    </VStack>
  );
});

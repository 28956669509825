import { CollectionObject, ReportKinds, ReportObject } from '@karya/core';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { apiUserBaseQuery } from './apiSlice';

export type RequestSource = 'mobile' | 'web';

export type GraphType = 'bar' | 'filtered-bar' | 'filtered-grouped-bar';

type ReportByKindAndIdRequest = {
  kind: ReportKind;
  id: string;
  src: RequestSource;
};

type ReportResultRequest = {
  kind: ReportKind;
  id: string;
  limit: number;
  page: number;
  params: string[];
  searchQuery?: Record<string, string>;
  src: RequestSource;
  forceRefresh?: boolean;
};

type ReportResultResponse = {
  meta: {
    columns: string[];
    kind: ReportKind;
    id: string;
  };
  data: Record<string, any>[];
  pagination: {
    page: number;
    limit: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
};

type ReportDownloadUrlRequest = {
  kind: ReportKind;
  id: string;
  params: string[];
  searchQuery?: Record<string, string>;
  src: RequestSource;
  mediaEnabled?: boolean;
  forceRefresh?: boolean;
};

type GraphResultRequest = {
  collectionId: string;
};

type GraphResultResponse = {
  data: {
    id: string;
    name: string;
    type: GraphType;
    data: any;
    yLabel: string;
  }[];
};

type AllReports = Record<ReportKind, ReportObject[]>;
const reportKinds = ReportKinds.filter((x) => x !== 'graphs');
export type ReportKind = (typeof reportKinds)[number];

/**
 * All API routes for reports
 */
export const reportsApi = createApi({
  reducerPath: 'reportsApi',

  baseQuery: apiUserBaseQuery,

  refetchOnReconnect: true,

  endpoints: (builder) => ({
    /**
     * Report endpoints
     */
    getAllReports: builder.query<AllReports, { src: RequestSource }>({
      query: ({ src }) => ({
        url: '/v2/reports/',
        method: 'GET',
        headers: {
          src,
        },
      }),
    }),

    getReportByKindAndId: builder.query<ReportObject, ReportByKindAndIdRequest>({
      query: ({ kind, id, src }) => {
        const url = src === 'mobile' ? `/mobile/v2/reports/${kind}/${id}` : `/v2/reports/${kind}/${id}`;
        return {
          url,
          method: 'GET',
          headers: {
            src,
          },
        };
      },
    }),

    getReportResult: builder.query<ReportResultResponse, ReportResultRequest>({
      query: (req) => {
        const { limit, page, params, kind, id, src, searchQuery, forceRefresh } = req;
        const encodedParams = encodeURIComponent(JSON.stringify(params));
        let url = `/v2/reports/${kind}/${id}/result?limit=${limit}&page=${page}&params=${encodedParams}`;
        if (searchQuery) url += `&searchQuery=${encodeURIComponent(JSON.stringify(searchQuery))}`;
        if (forceRefresh) url += '&forceRefresh=true';
        return {
          url: src === 'mobile' ? `/mobile${url}` : url,
          method: 'GET',
        };
      },
    }),

    getReportDownloadUrl: builder.query<{ url: string }, ReportDownloadUrlRequest>({
      query: (req) => {
        const { params, kind, id, src, mediaEnabled, searchQuery, forceRefresh } = req;
        const encodedParams = encodeURIComponent(JSON.stringify(params));
        let url = `/v2/reports/${kind}/${id}/download?params=${encodedParams}&mediaEnabled=${mediaEnabled || false}`;
        if (searchQuery) url += `&searchQuery=${encodeURIComponent(JSON.stringify(searchQuery))}`;
        if (forceRefresh) url += '&forceRefresh=true';
        return {
          url: src === 'mobile' ? `/mobile${url}` : url,
          method: 'GET',
        };
      },
    }),

    getAllGraphCollections: builder.query<Record<string, CollectionObject>, { src: RequestSource }>({
      query: ({ src }) => ({
        url: `/v2/reports/graphs`,
        method: 'GET',
        headers: {
          src,
        },
      }),
    }),

    getCollectionResult: builder.query<GraphResultResponse, GraphResultRequest>({
      query: ({ collectionId }) => ({
        url: `/v2/reports/graphs/${collectionId}/result`,
        method: 'GET',
        headers: {
          src: 'web',
        },
      }),
    }),
  }),
});

import {
  Center,
  Flex,
  Spinner,
  Table,
  TableBodyProps,
  TableCellProps,
  TableContainer,
  TableContainerProps,
  TableHeadProps,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { ColorMap } from 'src/themes/Attributes';

export type SimpleTableProps<T> = {
  columns: Array<[string, ((arg: T) => ReactNode) | keyof T]>;
  data?: T[];
  isLoading?: boolean;
  tableProps?: TableProps;
  headerProps?: TableHeadProps;
  containerProps?: TableContainerProps;
  columnHeaderProps?: TableCellProps;
  bodyProps?: TableBodyProps;
  cellProps?: TableCellProps;
  missingDataCaption?: string;
};

export function SimpleTable<T extends { [key: string]: any }>(props: SimpleTableProps<T>) {
  const headers = props.columns.map(([header, _value]) => header);
  const values = props.columns.map(([_header, value]) => value);

  return (
    <TableContainer width="100%" {...props.containerProps}>
      <Table {...props.tableProps}>
        <Thead {...props.headerProps}>
          <Tr>
            {headers.map((colName, index) => (
              <Th key={index} {...props.columnHeaderProps}>
                {colName}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody {...props.bodyProps}>
          {props.data?.map((element, row_index) => (
            <Tr key={row_index}>
              {values.map((value, col_index) => (
                <Td key={col_index} {...props.cellProps}>
                  {typeof value == 'function' ? value(element) : element[value]}
                </Td>
              ))}
            </Tr>
          ))}
          {!props.isLoading && props.data?.length === 0 && (
            <Tr>
              <Td colSpan={props.columns.length} color={ColorMap.stockGray} textAlign="center" {...props.cellProps}>
                {props.missingDataCaption ?? 'No data available'}
              </Td>
            </Tr>
          )}
          {props.isLoading && (
            <Tr>
              <Td colSpan={props.columns.length} color={ColorMap.stockGray} textAlign="center" {...props.cellProps}>
                <Center>
                  <Flex>
                    <Spinner />
                    <Text>Loading</Text>
                  </Flex>
                </Center>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

import { ArrowDownIcon, ArrowUpIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  ThemingProps,
  Tr,
  VStack,
} from '@chakra-ui/react';
import {
  ColumnDef,
  HeaderGroup,
  OnChangeFn,
  PaginationState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
  TableOptions,
  RowSelectionState,
} from '@tanstack/react-table';
import { ChangeEventHandler, useState } from 'react';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { ColorMap } from 'src/themes/Attributes';

export type AdvancedTableProps<T extends {}> = {
  columns: ColumnDef<T, any>[];
  pageSize?: number;
  data: T[];
  searchEnabled?: boolean;
  HeaderLeftComponents?: React.ReactNode;
  isLoading?: boolean;
  isError?: boolean;
  errorMsg?: string;
  noDataMsg?: string;
  paginationState?: PaginationState;
  onPaginationChange?: OnChangeFn<PaginationState>;
  manualPagination?: boolean;
  pageCount?: number;
  size?: ThemingProps<'Table'>['size'];
  selection?: {
    rowSelection: RowSelectionState;
    setRowSelection: OnChangeFn<RowSelectionState>;
  };
  autoResetPageIndex?: boolean;
};
export function AdvancedTable<T extends {} = { [key: string]: any }>(props: AdvancedTableProps<T>) {
  const {
    columns,
    data,
    searchEnabled,
    HeaderLeftComponents,
    pageSize = 10,
    paginationState,
    manualPagination = false,
    onPaginationChange,
    pageCount,
  } = props;

  const commonProps: TableOptions<any> = {
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: !!props.selection,
    onRowSelectionChange: props.selection?.setRowSelection ?? undefined,
    debugTable: true,
    autoResetPageIndex: props.autoResetPageIndex ?? true,
  };

  // Pass different props based on whether manualPagination is enabled
  const paginationProps = manualPagination
    ? {
        initialState: { pagination: { pageSize } },
        state: {
          rowSelection: props.selection?.rowSelection ?? undefined,
          pagination: paginationState,
        },
        pageCount,
        manualPagination,
        onPaginationChange,
      }
    : {
        initialState: { pagination: { pageSize } },
        getPaginationRowModel: getPaginationRowModel(),
        state: {
          rowSelection: props.selection?.rowSelection ?? undefined,
        },
      };

  const table = useReactTable({
    ...commonProps,
    ...paginationProps,
  });

  const [searchValue, setSearchValue] = useState<string>('');

  const handleSearchValueChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value ?? '');
    table.setGlobalFilter(e.target.value);
  };

  if (props.isLoading && data.length === 0) {
    return (
      <Flex justifyContent="center">
        <Spinner />
      </Flex>
    );
  }
  if (data.length === 0) {
    return (
      <Flex color={ColorMap.stockGray} justifyContent="center">
        {props.noDataMsg ?? 'No data available'}
      </Flex>
    );
  }
  if (props.isError) {
    return (
      <Flex color="red" justifyContent="center">
        {props.errorMsg ?? 'Something went wrong'}
      </Flex>
    );
  }

  return (
    <VStack width="100%" alignItems="stretch">
      {searchEnabled && (
        <HStack mb={'20px'}>
          {HeaderLeftComponents}
          <GlobalTableSearch
            value={searchValue ?? ''}
            onChange={handleSearchValueChange}
            onReset={() => setSearchValue('')}
          />
        </HStack>
      )}

      <TableContainer opacity={props.isLoading ? 0.4 : 1}>
        <Table variant="simple" size={props.size ?? { base: 'sm', md: 'md' }} overflowX="auto">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableHeader headerGroup={headerGroup} key={headerGroup.id} />
            ))}
          </Thead>
          <Tbody fontWeight="normal">
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>;
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      <PaginationBar
        paginationState={table.getState().pagination}
        canNextPage={table.getCanNextPage()}
        canPreviousPage={table.getCanPreviousPage()}
        count={table.getPageCount()}
        nextPage={table.nextPage}
        prevPage={table.previousPage}
        setPageIndex={table.setPageIndex}
      />
    </VStack>
  );
}

function TableHeader<T>(props: { headerGroup: HeaderGroup<T> }) {
  const { headerGroup } = props;
  return (
    <Tr key={headerGroup.id}>
      {headerGroup.headers.map((header) => {
        const headerElement = flexRender(header.column.columnDef.header, header.getContext());
        const sort = header.column.getIsSorted();
        return (
          <Th key={header.id} colSpan={header.colSpan}>
            <Flex align={'center'} gap={'10px'}>
              <Box as="span">{headerElement}</Box>
              <Box as="span">
                {sort === 'desc' && <ArrowDownIcon boxSize={3} ml={2} />}
                {sort === 'asc' && <ArrowUpIcon boxSize={3} ml={2} />}
              </Box>
            </Flex>
          </Th>
        );
      })}
    </Tr>
  );
}

type GlobalTableSearchProps = {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onReset: () => void;
};
function GlobalTableSearch(props: GlobalTableSearchProps) {
  const { value, onChange, onReset } = props;
  return (
    <InputGroup width="25%" minW="300px">
      <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
      <Input type="text" value={value} onChange={onChange} placeholder={`Search...`} />
      {value && (
        <InputRightElement
          cursor={'pointer'}
          children={<CloseIcon fontSize={14} _hover={{ color: 'gray.600' }} color="gray.300" />}
          onClick={onReset}
        />
      )}
    </InputGroup>
  );
}

type PaginationBarProps = {
  paginationState: PaginationState;
  count: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  setPageIndex: (index: number) => void;
  nextPage: () => void;
  prevPage: () => void;
};
function PaginationBar(props: PaginationBarProps) {
  const { paginationState, count, canPreviousPage, canNextPage, nextPage, prevPage, setPageIndex } = props;
  const { pageIndex } = paginationState;
  const gotoFirst = () => setPageIndex(0);
  const gotoLast = () => setPageIndex(count - 1);
  return (
    <Flex align={'center'} justify={'end'} gap={'5px'}>
      <Text mr={2} fontSize="sm">
        Page{'  '}
        <strong>
          {count ? pageIndex + 1 : count} of {count}
        </strong>
      </Text>
      <Button size={'xs'} onClick={gotoFirst} isDisabled={!canPreviousPage}>
        <Icon boxSize={4} as={FiChevronsLeft} />
      </Button>
      <Button size={'xs'} onClick={prevPage} isDisabled={!canPreviousPage}>
        <Icon boxSize={4} as={FiChevronLeft} />
      </Button>
      <Button size={'xs'} onClick={nextPage} isDisabled={!canNextPage}>
        <Icon boxSize={4} as={FiChevronRight} />
      </Button>
      <Button size={'xs'} onClick={gotoLast} isDisabled={!canNextPage}>
        <Icon boxSize={4} as={FiChevronsRight} />
      </Button>
    </Flex>
  );
}

// Access code version
export enum AccessCodeVersion {
  V0 = 0b00,
  V1 = 0b01,
  V2 = 0b10,
  V3 = 0b11,
}

export type AccessCodeInfo = {
  version?: AccessCodeVersion;
  length: number;
  env: string;
  physical_box: boolean;
  box_url: string;
  server_url: string;
  server_generated?: boolean;
  bulk_id?: string;
  bulk_name?: string;
  task_id: string;
};

export * as AccessCodeV0 from './versions/V0';
export * as AccessCodeV1 from './versions/V1';

import { ChakraProvider, CSSReset, defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './app/Store';
import './index.css';
import DefaultTheme from './themes/default/Index';

// Google client ID
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

// Chakra theme
const buttonVariants = Object.fromEntries(
  Object.entries(DefaultTheme.buttonTheme).map(([name, props]) => [name, defineStyle(props)])
);
const theme = extendTheme({
  colors: DefaultTheme.colors,
  components: { Button: defineStyleConfig({ variants: buttonVariants }) },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <GoogleOAuthProvider clientId={googleClientId}>
      <ChakraProvider theme={theme}>
        <CSSReset />
        <App />
      </ChakraProvider>
    </GoogleOAuthProvider>
  </Provider>
  // </React.StrictMode>
);

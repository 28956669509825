import { Box, HStack, Input, List, ListItem, Tag, TagCloseButton, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import Overlay from './Overlay'; // Assuming the file with the Overlay component is in the same directory

export type Category = {
  id: string;
  displayString: string;
};

export type Filter = {
  category: Category;
  input: string;
};

type GranularSearchProps = {
  categories?: Category[];
  setAppliedFilters?: (filters: Filter[]) => void;
  filters: Filter[];
  setFilters: (filters: Filter[]) => void;
};

const GranularSearch = ({
  categories = [],
  setAppliedFilters = (filters: Filter[]) => null,
  filters,
  setFilters,
}: GranularSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const updateFilters = (filters: Filter[]) => {
    setFilters(filters);
    setAppliedFilters(filters);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      overlayRef.current &&
      !overlayRef.current.contains(event.target as Node) &&
      inputRef.current &&
      !inputRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const removeFilter = (index: number) => {
    const updatedFilters = [...filters];
    updatedFilters.splice(index, 1);
    updateFilters(updatedFilters);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSuggestionClick = (category: Category) => {
    setIsOpen(false);
    updateFilters([...filters, { category, input: inputValue }]);
    setInputValue('');
  };

  return (
    <>
      <Box marginBottom="10px" position="relative">
        <Input
          ref={inputRef}
          placeholder="Search in columns..."
          onClick={handleOpen}
          onChange={handleInputChange}
          value={inputValue}
          width="100%"
          marginBottom="2"
        />
        <Box display="flex" flexWrap="wrap" gap="2" marginTop="2">
          {filters.map((filter, index) => (
            <Tag key={index} variant="subtle" colorScheme="blue" fontFamily="heading" size="sm">
              <Text textTransform="uppercase" fontWeight="bold">
                {filter.category.displayString}
              </Text>
              : {filter.input}
              <TagCloseButton onClick={() => removeFilter(index)} />
            </Tag>
          ))}
        </Box>
      </Box>
      <Overlay isOpen={isOpen} onClose={handleClose} referenceElement={inputRef.current}>
        {inputValue ? (
          <List
            bg="white"
            boxShadow="md"
            borderRadius="md"
            p="2"
            position="absolute"
            width={inputRef.current?.clientWidth}
            marginTop="5px"
          >
            {categories.map((category) => (
              <ListItem
                key={category.id}
                onClick={() => handleSuggestionClick(category)}
                cursor="pointer"
                _hover={{ bg: 'gray.100' }}
                width="100%"
                marginBottom="2px"
              >
                <HStack p="1">
                  <Text fontWeight="medium">{inputValue}</Text>
                  <Text>in</Text>
                  <Tag bg="gray.200" size="sm" textTransform="uppercase" fontFamily="heading">
                    {category.displayString}
                  </Tag>
                </HStack>
              </ListItem>
            ))}
          </List>
        ) : null}
      </Overlay>
    </>
  );
};

export default GranularSearch;

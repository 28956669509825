import type { ChartData, ChartOptions } from 'chart.js';
import { Chart as ChartJS, Colors } from 'chart.js';
import { forwardRef } from 'react';
import { Bar } from 'react-chartjs-2';

export type BarChartProps = {
  title?: string;
  options?: ChartOptions<'bar'>;
  data: ChartData<'bar'>;
};

ChartJS.register(Colors);

export const BarChart = forwardRef((props: BarChartProps, ref: any) => {
  return (
    <Bar
      ref={ref}
      data={props.data}
      options={{
        responsive: true,
        layout: {
          padding: {
            // right: 100,
          },
        },
        plugins: {
          // @ts-expect-error
          legend: {
            position: 'right',
            maxWidth: ({ chart }: any) => chart.width * 0.9,
          },
        },
        ...props.options,
      }}
    />
  );
});

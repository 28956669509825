import { DatapointRecord, GenericInputParam, GenericOutputParam, NgGenericTask } from '@karya/core';
import { ComponentRenderer } from 'src/components/TaskBuilder/Preview/ComponentRenderer';
import { parseComponent } from 'src/helpers/task-previews/Index';
import { useMemo } from 'react';
import { ComponentNode } from 'src/helpers/task-previews/Types';

const placeholderVoidFunc = () => {};

export type ReportComponenetPreviewMeta = {
  data: DatapointRecord['data'];
  files: DatapointRecord['files'];
  component: NgGenericTask[string];
  fileUrlMap?: Record<string, string>;
};

export function ReportComponenetPreview(props: ReportComponenetPreviewMeta) {
  // Update param sources of the component to use the output datapoint
  const sourceAdjustedCompnent = useMemo(() => {
    const updatedEntries = Object.entries(props.component).map(
      (entry: [string, GenericInputParam | GenericOutputParam | string]) => {
        if (typeof entry[1] == 'object' && 'src' in entry[1]) {
          entry[1] = { ...entry[1], src: 'MTA_OUTPUT' } as any;
        }
        return entry;
      }
    );
    return Object.fromEntries(updatedEntries) as NgGenericTask[string];
  }, [props.component]);

  const parsedNode = useMemo(() => {
    const params = parseComponent(
      sourceAdjustedCompnent,
      {
        spec: {} as any,
        mtaOutput: {
          data: props.data,
          files: props.files,
        },
        params: {},
        mtInput: { data: {} },
      },
      props.fileUrlMap
    );

    const node: ComponentNode = {
      params,
      component: sourceAdjustedCompnent,
    };
    return node;
  }, [sourceAdjustedCompnent, props.data, props.files]);

  return (
    <ComponentRenderer
      next={placeholderVoidFunc}
      writeOutput={placeholderVoidFunc}
      node={parsedNode}
      // Treat as non current component
      isCurrent={false}
      isDocked={false}
    />
  );
}

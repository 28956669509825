import { Box, Checkbox, Heading, HStack, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { BarChart } from './BarChart';

type FilteredBarChartProps = {
  title?: string;
  yLabel: string;
  data: {
    id: string;
    label: string;
    value: number;
  }[];
};

export const FilteredBarChart = ({ title, yLabel, data }: FilteredBarChartProps) => {
  const DEFAULT_SELECTED = Math.min(Math.floor(data.length / 2), 4);
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    [...Array(data.length)].map((_, i) => i < DEFAULT_SELECTED)
  );
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  const selectionData = data.filter((_, index) => checkedItems[index]);

  return (
    <Box height={650} boxShadow="md" rounded="lg" w="min" p={4} border="solid 1px" borderColor="gray.100">
      {title && (
        <Heading size="md" textAlign="center">
          {title}
        </Heading>
      )}
      <HStack w="min" height={600} py={8} px={4}>
        <VStack flex={1} align="start" height={600} width={150} py={4} overflow={'scroll'}>
          <Checkbox
            size="sm"
            isChecked={allChecked}
            isIndeterminate={isIndeterminate}
            onChange={(e) => setCheckedItems([...Array(data.length)].map(() => e.target.checked))}
          >
            Select All
          </Checkbox>
          <VStack pl={6} mt={1} align="start">
            {data.map((datum, index) => (
              <Checkbox
                key={datum.id}
                size="sm"
                isChecked={checkedItems[index]}
                onChange={(e) => {
                  const newCheckedItems = [...checkedItems];
                  newCheckedItems[index] = e.target.checked;
                  setCheckedItems([...newCheckedItems]);
                }}
              >
                {datum.label}
              </Checkbox>
            ))}
          </VStack>
        </VStack>
        <BarChart
          data={{
            labels: selectionData.map((datum) => datum.label),
            datasets: [
              {
                label: yLabel,
                data: selectionData.map((datum) => datum.value),
              },
            ],
          }}
        />
      </HStack>
    </Box>
  );
};

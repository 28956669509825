import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { GraphType, reportsApi } from 'src/features/reportsApi';
import { FilteredBarChart } from './FilteredBarChart';
import { FilteredGroupedBarChart } from './FilteredGroupedBarChart';

type GraphCollectionProps = {
  collectionId: string;
};

const { useGetCollectionResultQuery } = reportsApi;

export const GraphCollection = ({ collectionId }: GraphCollectionProps) => {
  const { data, isLoading, isError } = useGetCollectionResultQuery({ collectionId });

  if (isLoading) {
    return <Box mt={8}>Loading...</Box>;
  }

  if (isError) {
    return <Box mt={8}>An error occurred. Try again later.</Box>;
  }

  return (
    <Tabs variant="enclosed" mt={10}>
      <TabList>
        {data?.data.map((item: any) => {
          return <Tab key={item.id}>{item.name}</Tab>;
        })}
      </TabList>
      <TabPanels>
        {data?.data.map((item: any) => {
          return (
            // Display the respective graphs
            <TabPanel key={item.id} p={4}>
              <GraphPanel {...item} />
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};

type GraphPanelProps = {
  id: string;
  name: string;
  type: GraphType;
  data: any[];
  yLabel: string;
};

export const GraphPanel = ({ name, type, data, yLabel }: GraphPanelProps) => {
  const GraphComponent = type === 'filtered-bar' ? FilteredBarChart : FilteredGroupedBarChart;
  if (data && data.length) {
    return <GraphComponent title={name} yLabel={yLabel} data={data} />;
  }

  return <div>No data</div>;
};

import { Text } from '@chakra-ui/react';
import { BackgroundLocalComponent, GenericOutputParam } from '@karya/core';
import { ComponentNode } from 'src/helpers/task-previews/Types';
import { PlatformComponentRenderer } from './platform/Index';
import { UserComponentRenderer } from './user/Index';

export type ComponentRendererProps = {
  node: ComponentNode;
  writeOutput: (param: GenericOutputParam, value: any) => void;
  next: () => void;
  isCurrent: boolean;
  isDocked: boolean;
};
export function ComponentRenderer(props: ComponentRendererProps) {
  if (props.node.component.ctype == 'PLATFORM') {
    return <PlatformComponentRenderer {...props} />;
  }
  if (props.node.component.ctype == 'USER') {
    return <UserComponentRenderer {...props} />;
  }
  return null;
}

function BackgroundComponent({ component }: { component: BackgroundLocalComponent }) {
  return (
    <Text width="100%" textAlign="center">
      {component.function_id === 'SEGMENT_AUDIO' ? 'Segmenting Audio' : 'Checking Audio Files For Clipping'}
    </Text>
  );
}

import { UserAudioSegmentationComponent } from '@karya/core';
import React from 'react';
import { ParsedParams } from 'src/helpers/task-previews/Types';
import { AudioRenderer } from '../common/Audio';
import { ComponentRendererProps } from '../ComponentRenderer';

type AudioSegment = {
  start: number;
  end: number;
};
export function UserAudioSegmentRenderer(props: ComponentRendererProps) {
  // @ts-expect-errors
  const typedParams = props.node.params as ParsedParams<UserAudioSegmentationComponent>;
  const segments = typedParams.segments ?? [
    { start: 800, end: 2000 },
    { start: 3500, end: 6000 },
    {
      start: 7000,
      end: 9500,
    },
  ];

  return (
    <React.Fragment>
      {segments?.map((segment: AudioSegment, index) => {
        return <AudioRenderer src={typedParams.recording} start={segment.start} end={segment.end} key={index} />;
      })}
    </React.Fragment>
  );
}

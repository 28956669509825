import { Button, VStack } from '@chakra-ui/react';
import { UserBooleanComponent } from '@karya/core';
import { ParsedParams } from 'src/helpers/task-previews/Types';
import { ButtonVariant } from 'src/themes/ButtonVariants';
import { ComponentRendererProps } from '../ComponentRenderer';

export function UserBooleanRenderer(props: ComponentRendererProps) {
  // @ts-expect-error
  const typedParams = props.node.params as ParsedParams<UserBooleanComponent>;
  const component = props.node.component as UserBooleanComponent;

  const setSelected = (value: boolean) => {
    if (props.isCurrent) {
      props.writeOutput(component.selected, value);
    }
  };

  return (
    <VStack spacing="3px" width="150px" alignItems="stretch">
      <Button
        variant={typedParams.selected === true ? ButtonVariant.secondary : ButtonVariant.secondary_outlined}
        onClick={() => setSelected(true)}
      >
        {typedParams.true_label}
      </Button>
      <Button
        variant={typedParams.selected === false ? ButtonVariant.secondary : ButtonVariant.secondary_outlined}
        onClick={() => setSelected(false)}
      >
        {typedParams.false_label}
      </Button>
    </VStack>
  );
}

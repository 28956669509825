import { Box, BoxProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface OverlayProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  referenceElement: HTMLElement | null;
}

const Overlay = ({ children, isOpen, onClose, referenceElement }: OverlayProps) => {
  if (!isOpen) {
    return null;
  }

  const referenceRect = referenceElement?.getBoundingClientRect();
  const topPosition = referenceRect ? referenceRect.bottom : 0;
  const leftPosition = referenceRect ? referenceRect.left : 0;

  const boxProps: BoxProps = {
    position: 'fixed',
    top: `${topPosition}px`,
    left: `${leftPosition}px`,
    zIndex: '1', // Set the z-index lower than the reference element
    onClick: onClose, // Close the overlay when clicked (optional)
  };

  return <Box {...boxProps}>{children}</Box>;
};

export default Overlay;

import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { AvatarStatus, backendApi } from 'src/features/apiSlice';
import { useFlagEffect } from 'src/hooks/Misc';
import { ButtonVariant } from 'src/themes/ButtonVariants';

export type ChangeAvatarStatusModalProps = {
  disclosure: ReturnType<typeof useDisclosure>;
  avatarIds: string[];
  rerender: () => void;
  resetRowSelection?: () => void;
  status: AvatarStatus;
};
export function ChangeAvatarStatusModal(props: ChangeAvatarStatusModalProps) {
  const [avatarStatusTrigger, avatarStatusQuery] = backendApi.useSetAvatarStatusMutation();
  const handleDisableAvatar = () => {
    avatarStatusTrigger({
      disablePayments: false,
      status: AvatarStatus.DISABLED,
      avatar_ids: props.avatarIds,
    });
    props.rerender();
  };

  const handleDisableAvatarAndPayments = async () => {
    avatarStatusTrigger({
      disablePayments: true,
      status: AvatarStatus.DISABLED,
      avatar_ids: props.avatarIds,
    });
    props.rerender();
  };

  const handleEnableAvatar = async () => {
    avatarStatusTrigger({
      disablePayments: false,
      status: AvatarStatus.ENABLED,
      avatar_ids: props.avatarIds,
    });
  };

  useFlagEffect(avatarStatusQuery.isSuccess, () => {
    if (props.resetRowSelection) {
      props.resetRowSelection();
    }
    props.disclosure.onClose();
  });

  const enableButtons = (
    <Button onClick={handleEnableAvatar} disabled={avatarStatusQuery.isLoading}>
      Enable Avatar
    </Button>
  );
  const disableButtons = (
    <HStack gap="15px">
      <Button onClick={handleDisableAvatarAndPayments} disabled={avatarStatusQuery.isLoading}>
        Disable Avatar and Payments
      </Button>
      <Button onClick={handleDisableAvatar} disabled={avatarStatusQuery.isLoading}>
        Disable Avatar
      </Button>
    </HStack>
  );

  const actionButtons = props.status === AvatarStatus.ENABLED ? enableButtons : disableButtons;
  const modalHeaders = props.status === AvatarStatus.ENABLED ? 'Enable Avatar' : 'Disable Avatar(s)';
  const modalBody =
    props.status === AvatarStatus.ENABLED
      ? 'Please confirm if you want to enable the avatar'
      : 'Please confirm if you want to disable the avatar(s) and payments';

  return (
    <Modal {...props.disclosure} closeOnEsc={false} closeOnOverlayClick={false} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalHeaders}</ModalHeader>
        <ModalCloseButton disabled={avatarStatusQuery.isLoading} />
        <ModalBody>{modalBody}</ModalBody>
        <ModalFooter display="flex" justifyContent="space-between">
          <Button
            variant={ButtonVariant.secondary_outlined}
            onClick={props.disclosure.onClose}
            disabled={avatarStatusQuery.isLoading}
          >
            Cancel
          </Button>
          {actionButtons}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

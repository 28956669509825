import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { MouseEventHandler, useState } from 'react';
import { ColorMap } from 'src/themes/Attributes';
import { TagsList } from '../TagsList';
import { AddIcon } from '@chakra-ui/icons';
import { CreateProjectModal } from './CreateProject';
import { ButtonVariant } from 'src/themes/ButtonVariants';
import { useProjects } from 'src/hooks/Data';
import { useSelectProject } from 'src/hooks/SelectProject';
import { ProjectRecord } from '@karya/core';

export function ProjectModal(props: { disclosure: ReturnType<typeof useDisclosure>; disableClose?: boolean }) {
  const { disclosure } = props;
  const [activeId, setActiveId] = useState('1');
  const createProjectModal = useDisclosure();
  const projects = useProjects();

  const selectProject = useSelectProject();

  const handleProjectChange = (project: ProjectRecord) => {
    selectProject(project);
  };

  const handleOnSelectProject = () => {
    const project = projects.find((p) => p.id == activeId);
    if (!project) return;
    disclosure.onClose();
    createProjectModal.onClose();
    handleProjectChange(project);
  };

  return (
    <>
      <Modal
        isOpen={disclosure.isOpen !== createProjectModal.isOpen}
        onClose={disclosure.onClose}
        size={{ sm: 'lg', md: '2xl', lg: '4xl', xl: '6xl' }}
        closeOnEsc={!props.disableClose}
        closeOnOverlayClick={!props.disableClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Select Project</ModalHeader>
          <ModalCloseButton top="1rem" display={props.disableClose ? 'none' : undefined} />
          <ModalBody>
            <RadioGroup value={activeId}>
              <SimpleGrid spacing="20px" columns={{ sm: 1, md: 2, xl: 3 }}>
                {projects.map((project) => {
                  return (
                    <Card
                      key={project.id}
                      borderWidth="thin"
                      borderColor={ColorMap.stockGray}
                      onClick={() => setActiveId(project.id)}
                      cursor="pointer"
                    >
                      <CardHeader width="100%" paddingBottom={0}>
                        <Flex>
                          <VStack alignItems="start">
                            <Radio value={project.id} fontWeight="bold">
                              {project.name}
                            </Radio>
                            <HStack>
                              <TagsList tags={project.props?.tags ?? []} />
                            </HStack>
                          </VStack>
                        </Flex>
                      </CardHeader>
                      <CardBody paddingBottom={0}>{project.description}</CardBody>
                      <CardFooter>
                        <Badge colorScheme={project.status === 'ACTIVE' ? 'green' : 'cyan'}>{project.status}</Badge>
                      </CardFooter>
                    </Card>
                  );
                })}

                <CreateNewCard onClick={createProjectModal.onOpen} />
              </SimpleGrid>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button variant={ButtonVariant.submit} onClick={handleOnSelectProject}>
              Go To Project
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <CreateProjectModal disclosure={createProjectModal} projectModal={disclosure} />
    </>
  );
}

function CreateNewCard(props: { onClick: MouseEventHandler<HTMLDivElement> }) {
  return (
    <Box
      borderRadius="md"
      borderWidth="thin"
      borderStyle="dashed"
      borderColor={ColorMap.stockGray}
      cursor="pointer"
      sx={{
        ':hover': { bgColor: ColorMap.dirtyWhite },
        ':active': { bgColor: ColorMap.almostWhite },
      }}
      onClick={props.onClick}
      height="100%"
      minH="150px"
    >
      <Center height="100%">
        <Flex fontSize="md" fontWeight="md" textColor={ColorMap.deepSea} alignItems="center" gap="5px">
          <AddIcon />
          <Text>Create New</Text>
        </Flex>
      </Center>
    </Box>
  );
}

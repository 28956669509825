import { serverUserEndpointMap, serverUserRoleNames, UserProjectAcl } from '@karya/core';
import { ParameterForm } from '@karya/parameter-specs';
import { useMemo } from 'react';
import { useAppSelector } from 'src/app/Store';
import { backendApi } from 'src/features/apiSlice';
import { useProjectAcls, useServerUsers } from 'src/hooks/Data';
import { useGenericApiForm } from 'src/hooks/GenericForm';

export function useCreateAclForm() {
  const currentProjectId = useAppSelector((state) => state.currentProject.project?.id);
  const currentProjectName = useAppSelector((state) => state.currentProject.project?.name);
  const userAclsForProject = useProjectAcls(currentProjectId);
  const serverUsers = useServerUsers();
  const serverUsersWithoutAcl = useMemo(
    () => serverUsers.filter((user) => userAclsForProject.find((acl) => acl.user_id === user.id) === undefined),
    [serverUsers, userAclsForProject]
  );

  const registeredEmails = useMemo<{ [key: string]: string }>(
    () =>
      serverUsersWithoutAcl.reduce((prev, curr) => {
        if (!curr.email) {
          return prev;
        }
        return { ...prev, [curr.id]: curr.email };
      }, {}),
    [serverUsersWithoutAcl]
  );

  const parameters = useMemo<ParameterForm<UserProjectAcl>>(
    () => [
      {
        label: '',
        parameters: [
          {
            id: 'project_id',
            label: 'Project Id',
            type: 'fixed',
            value: currentProjectId ?? '',
            required: false,
          },
          {
            id: 'user_id',
            label: 'Email Id',
            description: 'Select the email id of the user you want to add to this project',
            type: 'enum',
            list: registeredEmails,
            required: true,
          },
          {
            id: 'role',
            label: 'User Role',
            description: 'Used to determine all the features that the user should be allowed to use',
            type: 'enum',
            list: serverUserRoleNames,
            required: true,
          },
        ],
        required: true,
      },
    ],
    [currentProjectId, registeredEmails]
  );

  const userProjectAclForm = useGenericApiForm({
    parameters,
    successToast: `Successfully added user to project - ${currentProjectName}`,
    errorToast: 'Failed to add user to project',
    apiMutation: backendApi.useCreateUserProjectAclsMutation,
    transform: (acl) => {
      return {
        ...acl,
        acl: { acl: serverUserEndpointMap[acl.role!] },
      };
    },
  });

  return { userProjectAclForm, userAclsForProject };
}

export function ReadFile(file: File) {
  const reader = new FileReader();
  reader.readAsText(file);
  const promise = new Promise<string>((resolve, reject) => {
    reader.addEventListener('load', () => resolve(reader.result as string), false);
    reader.addEventListener('error', () => reject(reader.error), false);
    reader.addEventListener('abort', () => reject(new Error('File read aborted')), false);
  });
  return promise;
}

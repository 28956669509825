import { BaseInputParam, BaseOutputParam, NgGenericTask } from '@karya/core';
import { Box, Card, CardBody, CardHeader, Flex, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import { PlatformComponentPreviewBody } from './platform/Index';
import { ReactNode } from 'react';
import { ColorMap } from 'src/themes/Attributes';
import { UserComponentPreviewBody } from './user/Index';
import { BackgroundLocalComponentPreviewBody } from './background/Index';
import { LoopComponentPreviewBody } from './loop/Index';
import { DeleteIcon } from '@chakra-ui/icons';
import _ from 'lodash';
import { StartComponentPreviewBody } from './start/Index';
import { EndComponentPreviewBody } from './end/Index';
import { ReadonlyTaskPreviewProps } from '../ReadonlyTaskPreview';

export function PreviewBodyHolder(props: { children: ReactNode }) {
  return (
    <VStack width="100%" alignItems="left" spacing="0px" marginTop="3px">
      {props.children}
    </VStack>
  );
}

export function InputSourcePreview({ label, param }: { label: string; param: BaseInputParam<any, any> | undefined }) {
  if (!param) return null;
  return (
    <Box>
      <Flex>
        <Text flex="1" fontWeight="medium">
          {label}
        </Text>
        <Text flex="2">
          {((param: BaseInputParam<any, any>) => {
            switch (param.src) {
              case 'CONSTANT':
                if (param.value instanceof File) {
                  return <Text as="span">{JSON.stringify(param.value.name)}</Text>;
                } else {
                  return <Text as="span">{JSON.stringify(param.value)}</Text>;
                }
              case 'MT_INPUT':
                return <Text as="span">input.{param.key}</Text>;
              case 'MTA_OUTPUT':
                return <Text as="span">output.{param.key}</Text>;
              case 'TASK_PARAM':
                return <Text as="span">task.param.{param.key}</Text>;
              case 'APP_PARAM':
                return <Text as="span">app_param.{param.key}</Text>;
              case 'ITER':
                return <Text as="span">iter.{param.key}</Text>;
              case 'WORKER_PROFILE':
                return <Text as="span">profile.{param.key}</Text>;
            }

            ((obj: never) => {
              console.log('this should never happen!');
            })(param);
          })(param)}
        </Text>
      </Flex>
    </Box>
  );
}

export function OutputTargetPreview({ label, param }: { label: string; param: BaseOutputParam<any> }) {
  return (
    <Box>
      <Flex>
        <Text flex="1" fontWeight="medium">
          {label}
        </Text>
        <Text flex="2">{param.key}</Text>
      </Flex>
    </Box>
  );
}

const previewCardBackgroundColor: {
  [id in NgGenericTask[string]['ctype']]: string;
} = {
  PLATFORM: ColorMap.almostWhite,
  USER: ColorMap.primaryBackground,
  BACKGROUND_LOCAL: ColorMap.lightGray,
  LOOP: ColorMap.white,
  START: ColorMap.skyBlue,
  END: ColorMap.skyBlue,
};

type ComponentPreviewBodyProps = {
  component: NgGenericTask[string];
};
function ComponentPreviewBody({ component }: ComponentPreviewBodyProps) {
  switch (component.ctype) {
    case 'START':
      return <StartComponentPreviewBody component={component} />;
    case 'PLATFORM':
      return <PlatformComponentPreviewBody component={component} />;
    case 'USER':
      return <UserComponentPreviewBody component={component} />;
    case 'BACKGROUND_LOCAL':
      return <BackgroundLocalComponentPreviewBody component={component} />;
    case 'LOOP':
      return <LoopComponentPreviewBody component={component} />;
    case 'END':
      return <EndComponentPreviewBody component={component} />;
  }

  ((obj: never) => {
    console.log('this should never happen!');
  })(component);
}

type ComponentPreviewProps = {
  component: NgGenericTask[string];
  deleteComponent: ReadonlyTaskPreviewProps['deleteComponent'];
};

export function ComponentPreview(props: ComponentPreviewProps) {
  const { component, deleteComponent } = props;

  return (
    <Card width="100%">
      <CardHeader py="10px">
        <Flex justifyContent="space-between" alignItems="center">
          <HStack>
            <Text fontWeight="medium" fontSize="20px">
              {component.name} ({component.key})
            </Text>
          </HStack>
          <IconButton
            aria-label="Delete Component"
            colorScheme="red"
            icon={<DeleteIcon />}
            onClick={() => deleteComponent(component.key)}
          />
        </Flex>
      </CardHeader>
      <CardBody backgroundColor={previewCardBackgroundColor[component['ctype']]}>
        {component.description && <Text>{component.description}</Text>}
        <ComponentPreviewBody component={component} />
      </CardBody>
    </Card>
  );
}
